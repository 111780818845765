import {
	CREATE_EVENT_TICKET, UPDATE_EVENT_TICKET_NAMES, DELETE_EVENT_TICKET,
	GET_TICKET, GET_TICKETS, DOWNLOAD_TICKETS, TRANSFER_EVENT_TICKET,
	GET_TICKETS_TO_CONFIRM, CONFIRM_TICKETS, UPDATE_EVENT_TICKET_REGION,
	GET_CHECKIN_SUMMARY, GET_ALL_TICKETS, GET_TICKETS_PAYMENTS,
	GET_TICKET_PAYMENT_DETAILS, USER_EVENT_TICKET_RESERVATION,
	GET_HOTEL_INFORMATION, DOWNLOAD_HOTEL_INFORMATION, UPDATE_EVENT_TICKET_NOTES,
	GET_FREE_EVENT_TICKETS, GET_FREE_EVENT_TICKETS_DATES, CREATE_FREE_EVENT_TICKETS,
	GET_FREE_TICKET_INFO, DELETE_FREE_TICKET,
} from '@/config/endpoint';
import { apiFilters } from '@/config/axios';
import Req from './AxiosRequest';

class EventTickets {
	constructor() {
		this.data = new Req(apiFilters);
		this.customDataObject = {};
		this.errors = this.data.errors;
		this.loading = this.data.loading;
		this.pagination = this.data.pagination;
	}

	clear() {
		this.data.clear();
	}

	getTicket(ticketId, options) {
		const { method, endpoint } = GET_TICKET;
		return this.data[method](endpoint(ticketId), options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getHotelInformation() {
		const { method, endpoint } = GET_HOTEL_INFORMATION;
		return this.data[method](endpoint).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getTickets(options) {
		const { method, endpoint } = GET_TICKETS;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getAllTickets(options) {
		const { method, endpoint } = GET_ALL_TICKETS;
		return this.data[method](endpoint, options, true).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getTicketsToConfirm(ticketNumber, options) {
		const { method, endpoint } = GET_TICKETS_TO_CONFIRM;
		return this.data[method](endpoint(ticketNumber), options, true).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getSummary(options) {
		const { method, endpoint } = GET_CHECKIN_SUMMARY;
		const queryParams = Req.setFilters(options);
		return this.data[method](endpoint(queryParams)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	confirmTickets(options) {
		const { method, endpoint } = CONFIRM_TICKETS;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	downloadTickets() {
		const { method, endpoint } = DOWNLOAD_TICKETS;
		return this.data.getBlobData({ method, url: endpoint }).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	downloadHotelInformation() {
		const { method, endpoint } = DOWNLOAD_HOTEL_INFORMATION;
		return this.data.getBlobData({ method, url: endpoint }).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	createTicket(options) {
		const { method, endpoint } = CREATE_EVENT_TICKET;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	updateTicketNames(ticketId, options) {
		const { method, endpoint } = UPDATE_EVENT_TICKET_NAMES;
		return this.data[method](endpoint(ticketId), options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	updateTicketRegion(ticketId, options) {
		const { method, endpoint } = UPDATE_EVENT_TICKET_REGION;
		return this.data[method](endpoint(ticketId), options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	updateTicketNotes(ticketId, options) {
		const { method, endpoint } = UPDATE_EVENT_TICKET_NOTES;
		return this.data[method](endpoint(ticketId), options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	transferTicket(ticketId, options) {
		const { method, endpoint } = TRANSFER_EVENT_TICKET;
		return this.data[method](endpoint(ticketId), options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	deleteTicket(ticketId, options) {
		const { method, endpoint } = DELETE_EVENT_TICKET;
		return this.data[method](endpoint(ticketId), options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getTicketsPayments(options) {
		const { method, endpoint } = GET_TICKETS_PAYMENTS;
		return this.data[method](endpoint, options, true).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getPaymentDetails(paymentId) {
		const { method, endpoint } = GET_TICKET_PAYMENT_DETAILS;
		return this.data[method](endpoint(paymentId)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	hotelReservation(ticketId, options) {
		const { method, endpoint } = USER_EVENT_TICKET_RESERVATION;
		return this.data[method](endpoint(ticketId), options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getFreeEventTickets() {
		const { method, endpoint } = GET_FREE_EVENT_TICKETS;
		return this.data[method](endpoint).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getFreeEventTicketsDates(eventCodeName) {
		const { method, endpoint } = GET_FREE_EVENT_TICKETS_DATES;
		return this.data[method](endpoint(eventCodeName)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getFreeEventTicketInfo(eventId) {
		const { method, endpoint } = GET_FREE_TICKET_INFO;
		return this.data[method](endpoint(eventId)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	deleteFreeTicket(eventId) {
		const { method, endpoint } = DELETE_FREE_TICKET;
		return this.data[method](endpoint(eventId)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	createFreeEventTicket(options) {
		const { method, endpoint } = CREATE_FREE_EVENT_TICKETS;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default EventTickets;
