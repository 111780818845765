import {
	EventTickets as eventTicketsMessages,
} from '@/translations';
import EventTickets from '@/util/EventTickets';
import CheckInItem from '@/views/Orders/components/CheckInItem';
import TicketInformation from '@/views/Orders/TicketInformation';
import EventBus from '@/util/eventBus';

export default {
	components: {
		TicketInformation,
		CheckInItem,
	},
	messages: [eventTicketsMessages],
	data() {
		return {
			eventTickets: new EventTickets(),
			confirmEventTickets: new EventTickets(),
			alert: new this.$Alert(),
			selected: null,
			countSelected: 0,
			searchTicketNumber: '',
		};
	},
	computed: {
		loading() {
			return !!this.eventTickets.data.loading;
		},
		data() {
			try {
				const { data } = this.eventTickets.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			const response = this.data.length;
			return !!response;
		},
		confirming() {
			return !!this.confirmEventTickets.data.loading;
		},
		confirmErrors() {
			return this.confirmEventTickets.data.errors;
		},
	},
	watch: {
		loading() {
			if (!this.loading && this.data.length === 0) {
				this.selected = null;
			}
		},
	},
	methods: {
		confirm(tickets, checkIn) {
			const ticketsToConfirm = tickets.map((ticket) => ticket.attributes.ticket_number);

			this.confirmEventTickets.confirmTickets({ tickets: ticketsToConfirm, check_in: checkIn })
				.then((response) => {
					ticketsToConfirm.forEach((ticketNumber) => {
						this.data.forEach((ticket) => {
							if (ticket.attributes.ticket_number === ticketNumber) {
								ticket.attributes.confirmed = checkIn;
							}
						});
					});
					this.alert.toast('success', response.response.message);
				})
				.catch(() => {
					this.confirmErrors.errors.already_updated.forEach((ticketNumber) => {
						this.data.forEach((ticket) => {
							if (ticket.attributes.ticket_number === ticketNumber) {
								ticket.attributes.confirmed = checkIn;
							}
						});
					});
					this.alert.toast('error', this.confirmErrors.messageError);
				})
				.finally(() => {
					this.confirmEventTickets.data.loading = false;
					this.updateCount();
					this.updateSummary();
				});
		},
		confirmAlert(titleKey, messageKey, tickets, checkIn = 1) {
			const options = {
				confirmButtonText: this.translate('yes'),
				cancelButtonText: this.translate('cancel'),
			};

			let message = this.translate(messageKey);
			if (checkIn === 1) {
				message += '<div class="mt-2" style="max-height: 150px; overflow-y: auto;"><ol style="text-align: left">';
				tickets.forEach((ticket) => {
					message += `<li>${ticket.attributes.ticket_number} - ${ticket.attributes.first_name} ${ticket.attributes.last_name}</li>`;
				});
				message += '</ol></div>';
				message += `<div class="text-primary font-weight-bold">${this.translate('total')}: ${tickets.length}</div>`;
			}

			this.alert.confirmation(this.translate(titleKey), message, options, true).then((result) => {
				if (result.value) {
					this.confirm(tickets, checkIn);
				}
			}).catch(() => {});
		},
		checkedTickets() {
			return this.data.filter((ticket) => ticket.checked && ticket.attributes.confirmed === 0);
		},
		handleChecked(ticket, checked) {
			ticket.checked = checked;
			this.updateCount();
		},
		checkableTickets() {
			return this.data.filter((ticket) => ticket.attributes.confirmed === 0).length;
		},
		updateCount() {
			this.countSelected = this.checkedTickets().length;
		},
		updateSummary() {
			EventBus.$emit('checkin_focus_ticket_number_input');
			EventBus.$emit('update:tickets_summary');
		},
	},
};
