<template>
	<div
		:style="borderStyle">
		<div
			class="d-flex align-items-center py-1"
			:class="ticket.attributes.confirmed ? 'ticket-confirmed' : checked ? 'ticket-checked' : ''"
			style="cursor: pointer"
			@click="selected()">
			<div
				v-if="$route.name === 'EventTicketsCheckIn'"
				class="mx-3">
				<div class="custom-control custom-checkbox">
					<input
						:id="'check'+ticket.id"
						v-model="checked"
						:disabled="ticket.attributes.confirmed === 1"
						type="checkbox"
						class="custom-control-input">
					<label
						class="custom-control-label pointer"
						:for="'check'+ticket.id" />
				</div>
			</div>
			<div
				v-else
				class="mx-2" />
			<div>
				<h6>{{ ticket.attributes.ticket_owner }} - {{ ticket.attributes.ticket_number }}</h6>
				<p class="text-muted m-0">
					{{ ticket.attributes.username }} ({{ ticket.attributes.user_id }})
				</p>
				<p class="text-muted m-0">
					{{ ticket.attributes.event.name }}
				</p>
			</div>
		</div>
		<hr
			class="m-0"
			style="background-color: rgba(115,114,114,0.15)">
	</div>
</template>
<script>
import EventBus from '@/util/eventBus';

export default {
	name: 'CheckInItem',
	props: {
		ticket: {
			type: Object,
			default: () => ({}),
		},
		selectedId: {
			type: String,
			default: '',
		},
		checkedImport: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			borderStyle: '',
			checked: this.checkedImport,
		};
	},
	watch: {
		checked() {
			this.$emit('checked', this.checked);
		},
		checkedImport() {
			this.checked = this.isConfirmed() ? true : this.checkedImport;
		},
		selectedId() {
			this.verifySelected();
		},
		// eslint-disable-next-line func-names
		'ticket.attributes.confirmed': function () {
			this.checked = this.isConfirmed();
		},
	},
	mounted() {
		EventBus.$on('refresh-checkin', () => {
			this.checked = this.isConfirmed();
		});
		this.checked = this.isConfirmed();
		this.verifySelected();
	},
	beforeDestroy() {
		EventBus.$off('refresh-checkin');
	},
	methods: {
		selected() {
			this.$emit('update:selectedTicket', this.ticket);
		},
		isConfirmed() {
			return this.ticket.attributes.confirmed === 1;
		},
		verifySelected() {
			this.borderStyle = this.selectedId === this.ticket.id
				? 'border-color: #F27813; border-right-width: thick; border-right-style: solid' : '';
		},
	},
};
</script>
<style scoped>
.ticket-checked {
	background-color: rgba(248, 173, 35, 0.35);
}

.ticket-confirmed {
	background-color: rgba(25, 25, 25, 0.1);
}
</style>
