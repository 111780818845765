<template>
	<div class="col-12 col-md mb-3">
		<b-card
			no-body
			class="h-100">
			<template v-slot:header>
				<h5 class="mb-0">
					{{ translate('ticket_information') }}
				</h5>
			</template>
			<b-card-body class="pb-0">
				<template v-if="ticket">
					<p class="mb-2">
						<strong>{{ translate('ticket_owner') }}:</strong> {{ ticket.attributes.first_name }} {{ ticket.attributes.last_name }}
					</p>
					<p class="my-2">
						<strong>{{ translate('ticket_number') }}:</strong> {{ ticket.attributes.ticket_number }}
					</p>
					<p class="my-2">
						<strong>{{ translate('event') }}:</strong> {{ ticket.attributes.event.name }}
					</p>
					<p class="my-2">
						<strong>{{ translate('user') }}:</strong> {{ ticket.attributes.username }} ({{ ticket.attributes.user_id }})
					</p>
					<p
						v-if="ticket.attributes.confirmed_at && ticket.attributes.confirmed === 1"
						class="my-2">
						<strong>{{ translate('confirmed') }}:</strong> {{ timeAgo(ticket.attributes.confirmed_at) }}
						{{ translate('by') }}
						{{ ticket.attributes.causer_username }} ({{ ticket.attributes.causer_id }})
						<span
							v-b-tooltip.hover
							:title="dateTooltip"
							class="text-small">
							<em class="fas fa-lg fa-info-circle" />
						</span>
					</p>
					<div>
						<div
							v-if="ticket.attributes.confirmed"
							class="text-center">
							<b-alert
								show
								variant="warning">
								<p>{{ translate('ticket_already_confirmed') }}</p>
								<b-button
									variant="primary"
									@click="cancelConfirm()">
									{{ translate('cancel_confirmation') }}
								</b-button>
							</b-alert>
						</div>
					</div>
				</template>
				<div
					v-else
					class="d-flex flex-column justify-content-center align-items-center h-100 text-center">
					<em class="fas fa-info mb-1" />
					<h6>{{ translate('select_to_view_information') }}</h6>
				</div>
			</b-card-body>
		</b-card>
	</div>
</template>

<script>
import {
	EventTickets as eventTicketsMessages,
} from '@/translations';
import { YMDHMS_FORMAT } from '@/settings/Dates';

export default {
	name: 'TicketInformation',
	messages: [eventTicketsMessages],
	props: {
		ticket: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			dateFormat: YMDHMS_FORMAT,
			alert: new this.$Alert(),
		};
	},
	computed: {
		dateTooltip() {
			return this.$moment(this.ticket.attributes.confirmed_at.date).format(this.dateFormat) ?? '';
		},
	},
	methods: {
		cancelConfirm() {
			this.$emit('cancelConfirmation', 'cancel_confirmation', 'cancel_confirmation_message', [this.ticket], 0);
		},
		timeAgo(date) {
			return this.$moment.tz(date.date, date.timezone).fromNow();
		},
	},
};
</script>
