<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="p-3">
		<b-form @submit.prevent="updateTicketNumber">
			<b-alert
				show
				v-html="translate('information')" />
			<div class="row no-gutters d-flex justify-content-end mb-3">
				<div class="col">
					<input
						ref="ticketNumber"
						v-model="searchTicketNumber"
						:placeholder="translate('enter_ticket_number')"
						type="text"
						class="form-control">
				</div>
				<div class="pl-1 col-auto">
					<button
						type="submit"
						class="btn btn-primary w-100">
						<template v-if="!loading">
							{{ translate('search') }}
						</template>
						<template v-else>
							<em class="fa fa-fw fa-spinner fa-pulse" />
						</template>
					</button>
				</div>
			</div>
		</b-form>
		<div class="row">
			<div class="col-12 col-md-7 col-lg-8 mb-3">
				<b-card
					no-body
					class="h-100">
					<template v-slot:header>
						<div class="col-auto pl-1 pt-1 float-left">
							<div class="pl-3 custom-control custom-checkbox">
								<input
									id="checkAll"
									v-model="checkAll"
									type="checkbox"
									:indeterminate.prop="countSelected > 0 && countSelected < checkableTickets()"
									class="custom-control-input">
								<label
									class="custom-control-label h5 pointer"
									for="checkAll">{{ translate('select_all') }}</label>
							</div>
						</div>
						<div class="float-left">
							<b-button
								v-b-tooltip.hover
								:title="translate('refresh')"
								variant="primary"
								size="sm"
								@click="refresh()">
								<em
									class="fas fa-sync"
									:class="loading ? 'fa-spin' : ''" />
							</b-button>
						</div>
						<div class="float-right">
							<b-button
								variant="primary"
								size="sm"
								:disabled="confirming || countSelected === 0"
								@click="confirmAlert('confirmation', 'confirmation_message', checkedTickets(), 1)">
								{{ translate('confirm') }}
							</b-button>
						</div>
						<div class="d-flex align-items-center float-right p-1">
							<span>
								{{ countSelected }} {{ translate('selected') }}
							</span>
						</div>
					</template>
					<b-card-body
						class="p-0"
						style="height: 25em; overflow-y: auto">
						<template	v-if="!loading && !firstEqualsSearch && lastTicket !== 'filter' && found">
							<div
								class="d-flex justify-content-center align-items-center text-center mb-0 mt-2"
								style="height: 3rem">
								<h6>
									<em class="fas fa-info-circle" />
									{{ translate('ticket_number_already_confirmed', { ticketNumber: lastTicket }) }}
								</h6>
							</div>
						</template>
						<template
							v-for="(ticket, index) in data">
							<div
								v-if="index === 0 && firstEqualsSearch"
								:key="ticket.id"
								class="mb-3">
								<CheckInItem
									:ticket="ticket"
									:checked-import="checkAll"
									:selected-ticket.sync="selected"
									:selected-id="selected ? selected['id'] : ''"
									@checked="handleChecked(ticket, $event)" />
							</div>
						</template>
						<div
							v-if="!loading && lastTicket !== 'filter' && (firstEqualsSearch ? data.length > 1 : data.length > 0)"
							class="mb-1 mx-3">
							<span class="font-weight-bold text-muted">{{ translate('other_tickets') }}</span>
						</div>
						<template
							v-for="(ticket, index) in data">
							<CheckInItem
								v-if="!loading && index >= (firstEqualsSearch ? 1 : 0)"
								:key="ticket.id"
								:ticket="ticket"
								:checked-import="checkAll"
								:selected-ticket.sync="selected"
								:selected-id="selected ? selected['id'] : ''"
								@checked="handleChecked(ticket, $event)" />
						</template>
						<div
							v-if="!found && (loading || !hasData)"
							class="d-flex justify-content-center align-items-center h-100">
							<is-loading
								:loading="loading"
								:has-data="hasData"
								:loading-label="translate('loading')"
								:no-data-label="translate('data_not_found')" />
						</div>
					</b-card-body>
				</b-card>
			</div>
			<ticket-information
				:ticket="selected"
				@cancelConfirmation="confirmAlert" />
		</div>
	</div>
</template>
<script>
import EventBus from '@/util/eventBus';
import CheckIn from '@/views/Orders/mixins/CheckIn';

export default {
	name: 'EventTicketsCheckIn',
	mixins: [CheckIn],
	data() {
		return {
			checkAll: false,
			firstEqualsSearch: false,
			lastTicket: '',
			found: false,
		};
	},
	watch: {
		countSelected() {
			if (this.countSelected === this.checkableTickets()) {
				this.checkAll = true;
			} else if (this.countSelected === 0) {
				this.checkAll = false;
			}
		},
	},
	mounted() {
		EventBus.$on('checkin_focus_ticket_number_input', () => {
			this.$refs.ticketNumber.focus();
		});
		if (this.hasFilter()) {
			this.getAllTickets();
		}
		this.$refs.ticketNumber.focus();
	},
	beforeDestroy() {
		EventBus.$off('checkin_focus_ticket_number_input');
	},
	methods: {
		getAllTickets() {
			this.firstEqualsSearch = false;
			this.lastTicket = 'filter';
			this.eventTickets.getAllTickets({ confirmed: 0 }).finally(() => {
				this.selected = null;
				this.updateSummary();
			});
		},
		getTickets(ticketNumber) {
			this.firstEqualsSearch = false;
			if (ticketNumber.trim()) {
				this.lastTicket = ticketNumber.trim().toUpperCase();
				this.eventTickets.getTicketsToConfirm(this.lastTicket, { confirmed: 0 }).then(() => {
					this.found = true;
					const item = this.data.find((ticket) => ticket.attributes.ticket_number.toUpperCase() === this.lastTicket);
					if (item) {
						this.firstEqualsSearch = true;
						this.selected = item;
					} else {
						this.selected = null;
					}
				}).catch(() => {
					this.found = false;
				}).finally(() => {
					this.updateSummary();
				});
			}
		},
		updateTicketNumber() {
			this.getTickets(this.searchTicketNumber);
			this.searchTicketNumber = '';
		},
		hasFilter() {
			const { query } = this.$route;
			return query.username !== undefined || query.ticket_number !== undefined || query.ticket_owner !== undefined;
		},
		refresh() {
			EventBus.$emit('refresh-checkin');
			switch (this.lastTicket) {
			case '': break;
			case 'filter': {
				this.getAllTickets();
				break;
			}
			default: {
				this.getTickets(this.lastTicket);
			}
			}
		},
	},
};
</script>
